import { Locale, defaultLocale } from "../constants/locale";
import { MGNL } from "../constants/magnolia";
import type {
  MgnlCompanyServiceNode,
  WifService,
} from "../types/wif-companies";
import { traverseObjectProperties } from "./object-utils";

export function jcrMultiFieldToArray<N>(
  jcrMultiField: Wif.Mgnl.NodeWithList<N>
): N[] {
  return (jcrMultiField?.["@nodes"] || [])
    .map((n) => jcrMultiField[n])
    .filter(Boolean);
}

export function formatAssetLink(url: string, isPreview = false): string {
  return MGNL.HOST(isPreview) + url;
}

/**
 * @param url string
 * @param isPreview boolean
 * @returns string
 */
export function formatPageLink(
  url: string,
  isPreview?: boolean,
  anchorTag?: string | null
) {
  const hash = anchorTag ? `#${anchorTag}` : "";

  if (!url) return hash;

  if (!isPreview) {
    if (url === MGNL.ROOT_NODE) {
      return "/" + hash;
    } else {
      return url.replace(MGNL.ROOT_NODE, "") + hash;
    }
  }
  return url + hash;
}

export function setURLSearchParams(
  uri: string,
  params: Record<string, string>
) {
  const url = new URL(uri);
  Object.entries(params).forEach(([name, value]) => {
    if (value) url.searchParams.set(name, value);
  });

  return url.toString();
}

export function getAssetPath(
  asset?: Wif.Mgnl.Video | Wif.Mgnl.Image,
  isPreview = false
): string {
  if (asset && asset["@link"]) {
    return MGNL.HOST(isPreview) + asset["@link"];
  }

  return "";
}

/**
 * Traverse a Magnolia node till the last subnode.
 * @param node The Mgnl.Node to traverse.
 * @param predicate The callback function with receives current node, parentNode and depth.
 *
 * @example
 * ```ts
 * const pageNode: Wif.Mgnl.Page = {...};
 * traverseMgnlNodeTree(pageNode, (node, parentNode) => {...})
 * ```
 */
export async function traverseMgnlNodeTree<
  R,
  N extends Wif.Mgnl.Node = Wif.Mgnl.Node
>(
  node: N,
  predicate: (
    node: N,
    parentNode: N | undefined,
    depth: number,
    previousResult?: R
  ) => Promise<R | undefined> | R | undefined,
  parentNode?: N,
  currentDepth = 0,
  previousResult?: R
): Promise<void> {
  const result = await predicate(
    node,
    parentNode,
    currentDepth,
    previousResult
  );

  if (result === null) return;

  const nodeList = node?.["@nodes"] || [];

  if (nodeList && Array.isArray(nodeList)) {
    await Promise.all(
      nodeList.map((nodeName) =>
        traverseMgnlNodeTree(
          node[nodeName],
          predicate,
          node,
          currentDepth + 1,
          result
        )
      )
    );
  }
}

/**
 * Extract a list of uuid in the provided Mgnl node
 * @param node Mgnl Node/Page/NavPage
 * @param matchPredicate Filter nodes that should be tested. Leave undefined for all nodes.
 * @param fields List of fields to test in a node. Default ["@id"];
 * @param existingUuidList List of existing uuid list. These uuid are ignored from returned list.
 * @returns A list of UUID.
 *
 * @example
 * ```ts
 * const uuidList = extractUuidListFromMgnlNodeTree(
 *  pageNode,
 *  (node) => node["@nodes"].length > 0,
 *  ["page", "field"],
 *  ["e3ea7c3b-2ef1-463a-9895-9ef53e28401", "e3ea7c3b-2ef1-463a-9895-9ef53e28439"]
 * )
 * ```
 */
export async function extractUuidListFromMgnlNodeTree<N extends Wif.Mgnl.Node>(
  node: N,
  matchPredicate?: (
    node: N,
    parentNode: N | undefined,
    depth: number
  ) => boolean,
  fields: string[] = ["@id"],
  existingUuidList: string[] = []
): Promise<string[]> {
  if (fields.length === 0) return [];

  const uuidList = new Set<string>();

  await traverseMgnlNodeTree(node, (node, parentNode, depth) => {
    if (typeof node !== "object") return;
    if (!matchPredicate || matchPredicate(node, parentNode, depth)) {
      fields.forEach((field) => {
        if (field in node) {
          const uuid = node[field];
          if (isValidMgnlUUID(uuid) && !existingUuidList.includes(uuid)) {
            uuidList.add(uuid);
          }
        }
      });
    }
  });

  return [...uuidList];
}

export function isValidMgnlUUID(uuid: string): boolean {
  // e3ea7c3b-2ef1-463a-9895-9ef53e28439
  // e3ea7c3b-2ef1-463a-9895-9ef53e284395
  return Boolean(typeof uuid === "string" && uuid.split("-").length === 5);
}

export function filterUniqueMgnlNodesPredicate(
  node: Wif.Mgnl.Node,
  index: number,
  nodes: Wif.Mgnl.Node[]
): boolean {
  return index === nodes.findIndex((n) => n["@id"] === node["@id"]);
}

export function removeMetadataPropertiesFromMgnlNode(
  node: Wif.Mgnl.Node,
  ...metaProperties: string[]
) {
  Object.values(node).forEach((property) => {
    if (property && typeof property === "object" && "metadata" in property) {
      metaProperties.forEach((metaProperty) => {
        if (metaProperty in property.metadata) {
          delete property.metadata[metaProperty];
        }
      });
    }
  });
}

export function removePropertiesFromMgnlNode(
  node: Wif.Mgnl.Node,
  ...properties: string[]
) {
  return traverseObjectProperties(
    node,
    properties,
    (_, obj, prop) => {
      delete obj[prop];
    },
    false
  );
}

/**
 * Format path based on target language and parent node's path.
 */
export function formatMgnlNodePath(
  node: Wif.Mgnl.Node,
  parentPath?: string,
  targetLang: string = defaultLocale,
  rootNodePath: string = MGNL.ROOT_NODE
): string {
  if (targetLang === defaultLocale)
    return node["@path"]?.replace(rootNodePath, "") || "/";
  if (!parentPath) return "/";

  const slug = node[`slug_${targetLang}`] || node["@name"];

  return `${parentPath}/${slug}`.replace("//", "/").replace(MGNL.ROOT_NODE, "");
}

export async function findNodeFromMgnlNodeWithUUID<N extends Wif.Mgnl.Node>(
  mgnlNode: N,
  uuid: string
) {
  let matchingNode: N | undefined;

  await traverseMgnlNodeTree(mgnlNode, (node) => {
    if (node["@id"] === uuid) {
      matchingNode = node;
      return null;
    }
  });

  return matchingNode;
}

/** Generate property that can be attached to MgnlSystemNode */
export function generateMgnlSystemNodeProperty(
  name: string,
  value: string | string[],
  type: magnolia.SystemNodeProperty["type"] = "String"
): magnolia.SystemNodeProperty {
  const multiple = Array.isArray(value);
  const values = multiple ? value : [value];

  return { name, values, type, multiple };
}

export function generateMgnlSystemNodeProperties(
  entries: Record<
    string,
    | string
    | string[]
    | undefined
    | { value: string | string[]; type: magnolia.SystemNodeProperty["type"] }
  >
): magnolia.SystemNodeProperty[] {
  const properties: magnolia.SystemNodeProperty[] = [];
  Object.entries(entries).forEach(([name, entry]) => {
    if (!entry) return;
    if (typeof entry === "object" && "value" in entry) {
      return properties.push(
        generateMgnlSystemNodeProperty(name, entry.value, entry.type)
      );
    }

    return properties.push(generateMgnlSystemNodeProperty(name, entry));
  });

  return properties;
}

export function transformMgnlServiceNodesToWifServices(
  services: MgnlCompanyServiceNode[],
  lang: string
): WifService[] {
  return (services || [])
    .filter((node) => node && typeof node === "object" && node["@id"])
    .map((serviceNode) => ({
      value: serviceNode["@id"],
      label: generateCompanyServiceLabel(serviceNode, lang),
    }));
}

export function generateCompanyServiceLabel(
  service: MgnlCompanyServiceNode,
  lang: string
): string {
  const fallback = service.name || service["@name"] || "";
  if (lang === Locale.Finnish) return service.nameFi || fallback;
  if (lang === Locale.Swedish) return service.nameSv || fallback;
  return fallback;
}

type RecordWithLocale<P extends string> = Partial<
  Record<`${P}-${"en" | "fi" | "sv"}`, string>
>;
export function extractLocaleDataFromNode<P extends string>(
  node: RecordWithLocale<P>,
  lang: string,
  property: P,
  defaultValue = ""
): string {
  const fallback = node[`${property}-fi`] || defaultValue;
  if (lang === Locale.English) return node[`${property}-en`] || fallback;
  if (lang === Locale.Swedish) return node[`${property}-sv`] || fallback;
  return fallback;
}

export function generateErrorResult<T>(): Wif.Mgnl.Results<T> {
  return {
    limit: 0,
    offset: 0,
    total: 0,
    results: [],
  };
}
